export const colorArray = [
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#4D8066',
    '#809980',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#6666FF',
];
